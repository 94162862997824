<template>
  <div class="" >
    <b-container fluid>
      <b-row class="pt-3 pb-3" style="background-color: #fff" align-v="center">
        <b-col
          lg="5" md="4" sm="4"
          style="font-size: 2rem;"
          class="pl-5 py-3"
        >
            <h3>{{ activeProject.name }}{{ isComparisson?` - ${comparissonProject.name}`:'' }}</h3>
        </b-col>
        <b-col class="action-btn-wrapper pr-5"
          lg="7" md="8" sm="8"
        >
          <!-- <b-button variant="white" class="border-black action-btn"
            ><img src="../assets/general-icons/download-icon.svg" alt="" /><span
              class="ml-2 action-custom-text"
              >{{ $t("download") }}</span 
            ></b-button
          >
          <b-button
            variant="white"
            class="border-black action-btn"
            v-if="!guestMode"
            @click="getShareLink()"
            v-b-modal.share-modal
            ><img src="../assets/general-icons/share-icon.svg" alt="" /><span
              class="ml-2 action-custom-text"
              >Share</span
            ></b-button
          > -->
          <b-button variant="none" style="color: #fff;" class="action-btn emotiva-pink"
            v-b-modal.compare-modal
            @click="getFolders()"
            ><img src="../assets/general-icons/compare-icon.svg" alt="" /><span
              class="ml-2"
              
              >{{ $t("page.results.compareResults") }}</span
            ></b-button
          >
        </b-col>
      </b-row>
    </b-container>

    <!-- SHARE MODAL -->
    <b-modal
      :title="$t('page.results.shareResults')"
      id="share-modal"
      header-class="py-2 align-items-center text-small"
      hide-footer
    >
      <b-input-group>
        <b-form-input
          style="font-size: 0.8rem"
          ref="share-input"
          :value="shareLink"
          readonly
          size="sm"
        >
        </b-form-input>

        <template v-slot:append>
          <b-button style="font-size: 0.8rem" @click="copyLink()" size="sm">
            {{ $t("button.copy") }}
          </b-button>
        </template>
      </b-input-group>
    </b-modal>

    <!-- COMPARE MODAL -->
    <b-modal
     @ok="submit"
     @cancel="cancelFolderSelection"
      :title="$t('page.results.compareResults')"
      id="compare-modal"
      header-class="py-2 align-items-center text-small"
    >
     <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase font-weight-bold">
        {{ $t("page.results.compareResults") }}
      </h5>
     </template>

     <template>
      <div v-if="folderModalStep=='folderSelection'" class="folder-selection-container">
        <!-- SELECT FOLDER -->
        <button class="no-decoration" @click="setSelectedFolder('home')" style="width: 100%;"
          :class="`${getSelectedFolder==='home'?'active':''}`"
        >
          <div class="d-flex align-items-center justify-content-between" >
            <div >
              <img src="../assets/general-icons/icon-home.svg" alt="" class="folder-icon">
              <span class="ml-3">{{ $t("sideMenu.home") }}</span>
            </div>
          </div>
        </button>


        <div class="folder-buttons-container">
          <button v-for="(folderItem,index) in allFolders" :key="index" class="no-decoration " @click="setSelectedFolder(folderItem._id)" style="width: 100%;"
            :class="`${folderItem._id===getSelectedFolder?'active':''}`"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div style="display: flex;">
                <img src="../assets/general-icons/icon-folder.svg" alt="" class="folder-icon">
                <span class="ml-3 textOverflow">{{ folderItem.name }}</span>
              </div>
            </div>
          </button>
        </div>
      </div>
      
      <!-- SELECT PROJECT -->
      <div v-if="folderModalStep=='projectSelection'" class="folder-selection-container">
        <div class="folder-buttons-container">
          <div v-if="projectList.length>0">
            <button v-for="(project,index) in projectList" :key="index" class="no-decoration " @click="setSelectedProject(project)" style="width: 100%;"
              :class="`${project===selectedProject?'active':''}`"
            >
              <div class="d-flex align-items-center justify-content-between" v-if="project._id !==activeProject._id">
                <div style="display: flex;">
                  <img :src="project.thumbnail" alt="" class="thumbnail-icon">
                  <span class="ml-3 textOverflow">{{ project.name }}</span>
                </div>
                <b-icon icon="chevron-right" style="color: #6200EE;"></b-icon>
              </div>
            </button>
          </div>
          <div v-else>
            <h5>{{ $t("page.results.emptyFolder") }}</h5>
            <button class="no-decoration" @click="reset">
              {{ $t("page.results.selectDifferentFolder") }}
            </button>
          </div>
        </div>
      </div>

     </template>

     <template v-slot:modal-footer="{ok,cancel}">
      <b-button @click="cancel" class="action-btn border-black" variant="none" style="color: var(--emotiva-white-text);">
        {{ $t("button.cancel") }}
      </b-button>
      <b-button variant="none" style="color: #fff;" class="action-btn emotiva-pink-background" v-if="folderModalStep==='folderSelection'" @click="nextStep('projectSelection')" :disabled="selectedFolder===''">
        {{ $t("button.continue") }}
      </b-button>
      <b-button variant="none" style="color: #fff;" class="action-btn emotiva-pink-background" v-if="folderModalStep==='projectSelection'" @click="ok" :disabled="selectedProject===''">
        {{ $t("page.results.compareResults") }}
      </b-button>
     </template>

  </b-modal>
    <div>
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import Config from "../plugins/Config";
export default {
  data() {
    return {
      analysis: null,
      active: "score",
      shareLink: "",
      activeVideo: null,
      activeVideoDuration: "N/A",
      shareModalStatus: false,
      compareModalStatus: false,
      folders:[],
      homeFolder:{},
      folderModalSteps:['folderSelection','projectSelection'],
      currentFolderModalStep:'folderSelection',
      projects :[],
      selectedProject:{}, // Selected project to compare
      selectedFolder:'',
    };
  },

  async created() {
    am4core.addLicense(Config.value("amchartsLicense"));
  },
  mounted() {
    if(this.$route.name==='project-analysis')
      this.$router.push(this.heatmapRoute); // the default
  },

  computed: {
    activeProject(){
      return this.$store.getters['project/getActiveProject']
    },
    comparissonProject(){
      return this.$store.getters['project/getProjectToCompare']
    },
    guestMode(){
      return this.$store.getters['analysisV2/isGuestMode']
    },

    allProjects(){
      return this.homeFolder;
    },

    allFolders(){
      return this.folders
    },
    folderModalStep(){
      return this.currentFolderModalStep;
    },

    heatmapRoute() {
      return this.guestMode ? { name: "shareHeatmap" } : { name: "heatmap" };
    },
    displayInnerNavigation() {
      return false;
    },

    isProjectSelectionVisible() {
      return !this.guestMode;
    },
    getSelectedFolder(){
      return this.selectedFolder;
    },
    projectList(){
      return this.projects.filter((project)=>project._id !== this.activeProject._id);
    },
    isComparisson(){
      return this.$route.path.includes('comparisson') 
    }
    
  },

  methods: {
    async getFolders(){
      // this.folders = this.$store.dispatch("folder/retrieveFoldersByClientId");
      this.folders = this.$store.getters["folder/getFolderList"];
      if(!this.folders || this.folders === []){
        this.folders = await this.$store.dispatch("folder/retrieveFoldersByClientId");
      }
    },
    async getAllProjects(){
      let tempProjects = this.$store.getters["project/getProjectList"]
      if(!tempProjects){
        await this.$store.dispatch("project/retrieveProjectsByClientId")
          .then(()=>{
            tempProjects = this.$store.getters["project/getProjectList"];
            this.projects = tempProjects[2].list
          }).catch((err)=>{
          console.log(err);
        })
      }
      this.projects = tempProjects[2].list
    },
    async getProjectsFromFolder(folderId) {
      const folderIndex = this.folders.findIndex((folder) => folder._id === folderId);
      if (folderIndex === -1 || this.folders[folderIndex].projectDetails) return;
      this.$store.dispatch("folder/retrieveFolderByClientId", folderId)
        .then(()=>{
          const tempProjects = this.$store.getters["folder/getProjectList"];
          this.projects = tempProjects[2].list
        }).catch((err)=>{
          console.log(err);
        })
    },

    copyLink() {
      this.$refs["share-input"].select();
      document.execCommand("copy");
      this.$bvModal.hide("share-modal");
    },
    async nextStep(step){
      if(this.currentFolderModalStep === 'folderSelection'){
        this.selectedProject=''
        if(this.selectedFolder==='home'){
          this.getAllProjects();
        }else{
          this.getProjectsFromFolder(this.selectedFolder)
        }
      }else if(this.currentFolderModalStep === 'projectSelection'){
        
      }
      this.currentFolderModalStep=step;
    },
    cancelFolderSelection(){
      this.projects=[]
      this.currentFolderModalStep='folderSelection'
    },
    submit(){
      this.nextStep("folderSelection")
      if(this.$route.path.includes('comparisson')) 
        this.$router.push(`${this.selectedProject._id}`)
      else
        this.$router.push(`comparisson/${this.selectedProject._id}`)
      this.reset();
    },
    initializeVideo() {
      this.activeVideo = this.$refs.video;

      if (this.activeVideo) {
        const time = this.activeVideo.duration;
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time - minutes * 60);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.activeVideoDuration = `${minutes}:${seconds}`;
      } else this.activeVideoDuration = "N/A";
    },
    setSelectedFolder(folderId){
      this.selectedFolder=folderId
    },
    setSelectedProject(project){
      this.selectedProject=project;
    },
    reset(){
      this.currentFolderModalStep='folderSelection'
      this.projects =[]
      this.selectedProject={} // Selected project to compare
      this.selectedFolder=''
      // this.folders=[]
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name==="Comparisson") {
      this.$forceUpdate();
      next();
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.content {
  padding-left: 24px;
  padding-right: 10px;
  padding-top: 20px;
}

.total-complete-text {
  font-size: 1.1rem;
}

.outer-navbar {
  z-index: 1;
  position: relative;
}

.inner-navbar::before {
  content: "";
  top: -7px;
  left: 30px;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 10px solid #f8f9fa;
}

.inner-navbar {
  position: absolute;
  top: 55px;
  left: 158px;
}

.underline > .text {
  padding-bottom: 4px;
  border-bottom: 2px solid var(--red);
}

.route-active {
  color: var(--red);
}

.route-link:hover {
  cursor: pointer;
  opacity: 0.9;
}

.text-small {
  font-size: 0.8rem !important;
  font-weight: 700 !important;
}

.active-link {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--red);
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
}

.action-btn-wrapper {
  gap:10px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.action-btn {
  border-radius: 26px;
  height: 46px;
  min-width: 100px;
}

.border-black {
  border: 2px solid #CBD2E0;
  /* Change border-width to 2px */
  border-color: #CBD2E0;
}
.folder-icon{
  width: 20px;
  height: 20px;
}
.thumbnail-icon{
  width: 30px;
  height: 30px;
}

.no-decoration {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
  height: 48px;
}
.active {
 background-color: #F2E7FE;
}
.no-decoration:hover{
  background-color: rgba(242, 231, 254, 0.6);
}


.folder-selection-container {
  max-height: 215px; 
  overflow-y: auto;
}

.folder-buttons-container button {
  display: block;
  width: 100%;
}
.folder-selection-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.folder-selection-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.textOverflow {
  white-space: nowrap; 
  width: 380px; 
  overflow: hidden;
  text-overflow: ellipsis; 
  text-align: start;
}
.action-custom-text{
  color: #717D96;
  font-weight: 700;
}
.emotiva-pink{
  background-color: #6200EE;
}

</style>
